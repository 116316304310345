import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "progress", "progressBar", "uploadText", "uploadIcon", "dropzone"]
    static values = {
        url: String,
        maxFileSize: { type: Number, default: 100 }
    }

    connect() {
        this.element.addEventListener("dragenter", this.dragenter.bind(this))
        this.element.addEventListener("dragover", this.dragover.bind(this))
        this.element.addEventListener("dragleave", this.dragleave.bind(this))
        this.element.addEventListener("drop", this.drop.bind(this))
    }

    dragenter(e) {
        e.preventDefault()
        e.stopPropagation()
        this.element.classList.add('drag-active')
    }

    dragover(e) {
        e.preventDefault()
        e.stopPropagation()
        this.element.classList.add('drag-active')
    }

    dragleave(e) {
        e.preventDefault()
        e.stopPropagation()
        this.element.classList.remove('drag-active')
    }

    drop(e) {
        e.preventDefault()
        e.stopPropagation()
        this.element.classList.remove('drag-active')
        const files = e.dataTransfer.files
        this.handleFiles(files)
    }

    browse(e) {
        this.inputTarget.click()
    }

    handleChange(e) {
        const files = this.inputTarget.files
        this.handleFiles(files)
    }

    handleFiles(files) {
        if (files.length === 0) return

        const file = files[0]
        if (!this.validateFile(file)) return

        const formData = new FormData()
        formData.append('file', file)
        this.uploadFile(formData)
    }

    validateFile(file) {
        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png']

        if (!allowedTypes.includes(file.type)) {
            alert('Only PDF, JPG, JPEG, and PNG files are allowed')
            return false
        }

        if (file.size > this.maxFileSizeValue * 1024 * 1024) {
            alert(`File size must be less than ${this.maxFileSizeValue}MB`)
            return false
        }

        return true
    }

    uploadFile(formData) {
        // Show progress bar
        this.progressTarget.classList.remove('d-none')
        this.uploadTextTarget.textContent = 'Uploading...'

        const xhr = new XMLHttpRequest()

        xhr.upload.addEventListener('progress', (e) => {
            if (e.lengthComputable) {
                const percentComplete = (e.loaded / e.total) * 100
                this.progressBarTarget.style.width = percentComplete + '%'
                this.progressBarTarget.setAttribute('aria-valuenow', percentComplete)
            }
        })

        xhr.addEventListener('load', () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                // Use the global Turbo object to process the response
                const parser = new DOMParser()
                const html = parser.parseFromString(xhr.responseText, 'text/html')
                const streamElements = html.querySelectorAll('turbo-stream')
                streamElements.forEach(element => {
                    window.Turbo.renderStreamMessage(element.outerHTML)
                })
                this.showSuccess()
            } else {
                this.showError('Upload failed')
            }
        })

        xhr.addEventListener('error', () => {
            this.showError('Network error occurred')
        })

        xhr.open('PATCH', this.urlValue)
        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]')?.content)
        xhr.setRequestHeader('Accept', 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml')
        xhr.send(formData)
    }

    showSuccess() {
        this.uploadIconTarget.classList.remove('fa-cloud-upload-alt')
        this.uploadIconTarget.classList.add('fa-check-circle')
        this.uploadIconTarget.style.color = '#198754'
        this.uploadTextTarget.textContent = 'Upload successful!'
        this.progressTarget.classList.add('d-none')
        this.element.classList.add('upload-success')

        setTimeout(() => {
            this.resetUploadState()
        }, 3000)
    }

    showError(message) {
        this.uploadIconTarget.classList.remove('fa-cloud-upload-alt')
        this.uploadIconTarget.classList.add('fa-exclamation-circle')
        this.uploadIconTarget.style.color = '#dc3545'
        this.uploadTextTarget.textContent = message
        this.progressTarget.classList.add('d-none')
        this.element.classList.add('upload-error')
    }

    resetUploadState() {
        this.uploadIconTarget.classList.remove('fa-check-circle', 'fa-exclamation-circle')
        this.uploadIconTarget.classList.add('fa-cloud-upload-alt')
        this.uploadIconTarget.style.color = ''
        this.uploadTextTarget.textContent = 'Drop your file here'
        this.progressBarTarget.style.width = '0%'
        this.progressBarTarget.setAttribute('aria-valuenow', 0)
        this.element.classList.remove('upload-success', 'upload-error')
        this.progressTarget.classList.add('d-none')
        this.inputTarget.value = ''
    }

    disconnect() {
        this.element.removeEventListener("dragenter", this.dragenter)
        this.element.removeEventListener("dragover", this.dragover)
        this.element.removeEventListener("dragleave", this.dragleave)
        this.element.removeEventListener("drop", this.drop)
    }
}