// app/javascript/controllers/dashboard/notes_controller.js

import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'
import 'choices.js/public/assets/styles/choices.min.css'

export default class extends Controller {
    static targets = ["form", "taskFields", "content", "assignedTo", "deadline"]

    connect() {

        this.initializeChoices()



    }

    initializeChoices() {
        const choicesSelects = this.element.querySelectorAll('select[data-controller="choices"]')
        // Store all Choices instances for proper cleanup
        this.choicesInstances = []

        choicesSelects.forEach(select => {
            const choicesInstance = new Choices(select, {
                removeItemButton: select.dataset.choicesRemoveItemButtonValue === 'true',
                placeholder: false,
                classNames: {
                    containerOuter: 'choices form-control form-control-light'
                }
            })
            this.choicesInstances.push(choicesInstance)
        })
    }

    expandForm(event) {
        this.formTarget.classList.add("expanded")
        this.element.querySelector("#newNoteForm").classList.add("show")
    }

    collapseForm(event) {
        event.preventDefault()
        this.formTarget.classList.remove("expanded")
        this.element.querySelector("#newNoteForm").classList.remove("show")
        event.target.closest("form").reset()
    }

    toggleTaskFields(event) {
        const taskFields = this.element.querySelector("#taskFields")
        if (event.target.checked) {
            taskFields.classList.add("show")
            // Make fields required when task is checked
            if (this.hasAssignedToTarget) this.assignedToTarget.required = true
            if (this.hasDeadlineTarget) this.deadlineTarget.required = true
        } else {
            taskFields.classList.remove("show")
            // Remove required when task is unchecked
            if (this.hasAssignedToTarget) this.assignedToTarget.required = false
            if (this.hasDeadlineTarget) this.deadlineTarget.required = false
        }
    }

    toggleComplete(event) {
        const form = event.target.closest("form")
        form.requestSubmit()
    }

    validateForm(event) {
        if (this.hasContentTarget && !this.contentTarget.value.trim()) {
            event.preventDefault()
            this.contentTarget.classList.add("is-invalid")
            return
        }

        // Additional validation for task fields
        if (event.target.querySelector("[name='note[is_task]']").checked) {
            if (this.hasAssignedToTarget && !this.assignedToTarget.value) {
                event.preventDefault()
                this.assignedToTarget.classList.add("is-invalid")
                return
            }

            if (this.hasDeadlineTarget && !this.deadlineTarget.value) {
                event.preventDefault()
                this.deadlineTarget.classList.add("is-invalid")
                return
            }
        }
    }

    // Remove invalid state on input
    resetValidation(event) {
        event.target.classList.remove("is-invalid")
    }

    disconnect() {
        // Clean up all Choices instances
        if (this.choicesInstances) {
            this.choicesInstances.forEach(choice => choice.destroy())
        }
    }

}