import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "list", "item", "content", "switch"]

  initialize() {
    // Set the base URL depending on environment
    this.baseUrl = window.location.host.includes('dashboard.') ? '' : '/dashboard'
  }

  filter() {
    const query = this.inputTarget.value.toLowerCase()
    const items = this.listTarget.querySelectorAll("[data-search-text]")

    items.forEach(item => {
      const text = item.dataset.searchText.toLowerCase()
      item.style.display = text.includes(query) ? "" : "none"
    })
  }

  select(event) {
    this.itemTargets.forEach(item => item.classList.remove("selected"))
    event.currentTarget.classList.add("selected")

    const code = event.currentTarget.dataset.countryCode
    this.fetchCountryDetails(code)
  }

  async fetchCountryDetails(code) {
    try {
      const response = await fetch(`${this.baseUrl}/setting/country/${code}`)
      if (!response.ok) throw new Error('Failed to fetch country details')
      const html = await response.text()
      this.contentTarget.innerHTML = html
    } catch (error) {
      console.error('Error fetching country details:', error)
      this.contentTarget.innerHTML = '<div class="alert alert-danger">Failed to load country details</div>'
    }
  }

  async toggle(event) {
    const { setting, countryCode } = event.target.dataset
    const enabled = event.target.checked

    try {
      const response = await fetch(`${this.baseUrl}/setting/country/${countryCode}/settings`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          setting,
          enabled
        })
      })

      if (!response.ok) throw new Error('Failed to update setting')

      // Update all related icons in both the list and detail views
      this.updateIcons(countryCode, setting, enabled)
    } catch (error) {
      console.error('Error updating setting:', error)
      // Revert the toggle
      event.target.checked = !enabled
      // Show error message
      const errorToast = `
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-header bg-danger text-white">
            <strong class="me-auto">Error</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Failed to update setting. Please try again.
          </div>
        </div>
      `
      document.body.insertAdjacentHTML('beforeend', errorToast)
      const toast = document.body.lastElementChild
      new bootstrap.Toast(toast).show()
    }
  }

  updateIcons(countryCode, setting, enabled) {
    // Update icons in both list and detail views
    const iconName = this.getIconName(setting)
    const icons = document.querySelectorAll(`[data-country-code="${countryCode}"] .fa-${iconName}`)

    icons.forEach(icon => {
      const iconContainer = icon.closest('.icon') // Get the parent .icon container
      if (iconContainer) {
        iconContainer.classList.toggle('active', enabled)
        iconContainer.classList.toggle('disabled', !enabled)
      }
    })
  }

  getIconName(setting) {
    const icons = {
      residency: 'house-building',
      documents: 'passport',
      visiting: 'location-dot'
    }
    return icons[setting]
  }
}