// magnifier_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["image"]
    static values = {
        zoom: { type: Number, default: 2 }
    }

    connect() {

        this.setupMagnifier()

    }

    setupMagnifier() {
        this.img = this.imageTarget
        this.createGlass()
        this.setupGlassProperties()
        this.addEventListeners()
    }

    createGlass() {
        this.glass = document.createElement("DIV")
        this.glass.setAttribute("class", "img-magnifier-glass")
        this.img.parentElement.insertBefore(this.glass, this.img)
    }

    setupGlassProperties() {
        this.glass.style.backgroundImage = `url('${this.img.src}')`
        this.glass.style.backgroundRepeat = "no-repeat"
        this.glass.style.backgroundSize = `${this.img.width * this.zoomValue}px ${this.img.height * this.zoomValue}px`

        this.bw = 3
        this.w = this.glass.offsetWidth / 2
        this.h = this.glass.offsetHeight / 2
    }

    addEventListeners() {
        this.glass.addEventListener("mousemove", this.moveMagnifier.bind(this))
        this.img.addEventListener("mousemove", this.moveMagnifier.bind(this))
        this.glass.addEventListener("touchmove", this.moveMagnifier.bind(this))
        this.img.addEventListener("touchmove", this.moveMagnifier.bind(this))
    }

    moveMagnifier(e) {
        e.preventDefault()
        const pos = this.getCursorPos(e)
        let x = pos.x
        let y = pos.y

        // Prevent magnifier from going outside the image
        if (x > this.img.width - (this.w / this.zoomValue)) {
            x = this.img.width - (this.w / this.zoomValue)
        }
        if (x < this.w / this.zoomValue) {
            x = this.w / this.zoomValue
        }
        if (y > this.img.height - (this.h / this.zoomValue)) {
            y = this.img.height - (this.h / this.zoomValue)
        }
        if (y < this.h / this.zoomValue) {
            y = this.h / this.zoomValue
        }

        // Set glass position
        this.glass.style.left = `${x - this.w}px`
        this.glass.style.top = `${y - this.h}px`

        // Set background position
        this.glass.style.backgroundPosition = `-${(x * this.zoomValue) - this.w + this.bw}px -${(y * this.zoomValue) - this.h + this.bw}px`
    }

    getCursorPos(e) {
        e = e || window.event
        const a = this.img.getBoundingClientRect()
        let x = e.pageX - a.left
        let y = e.pageY - a.top

        // Account for page scrolling
        x = x - window.pageXOffset
        y = y - window.pageYOffset

        return { x, y }
    }

    disconnect() {
        // Clean up event listeners
        this.glass?.remove()
    }
}