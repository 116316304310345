import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input";

export default class extends Controller {
  static values = {
    defaultCountry: String
  }

  connect() {

    var input = document.querySelector("#applicant_mobile_phone");
    var iti = intlTelInput(input, {
      initialCountry: this.defaultCountryValue, // Access the value here
      strictMode: true,
      nationalMode: true,
      hiddenInput: () => ({ phone: "full_phone", country: "country_code" }),
      loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
    });
  }
}