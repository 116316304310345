import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["sessionLink", "qrImage", "toast", "toastMessage"]

    async copyLink(event) {
        event.preventDefault()
        const link = this.sessionLinkTarget.textContent
        await this.copyToClipboard(link)
        this.showToast("Session link copied to clipboard")
    }

    async copyQRCode(event) {
        event.preventDefault()
        const qrImage = this.qrImageTarget
        try {
            const blob = await fetch(qrImage.src).then(r => r.blob())
            const item = new ClipboardItem({ "image/png": blob })
            await navigator.clipboard.write([item])
            this.showToast("QR code image copied to clipboard")
        } catch (error) {
            console.error("Failed to copy QR code:", error)
            this.showToast("Failed to copy QR code image")
        }
    }

    async copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text)
            return true
        } catch (error) {
            console.error("Failed to copy text:", error)
            return false
        }
    }

    showToast(message) {
        this.toastMessageTarget.textContent = message
        const toast = new bootstrap.Toast(this.toastTarget)
        toast.show()
    }
}