// app/javascript/controllers/image_cycler_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["image", "boundingBox"]
    static values = {
        urls: Array,
        interval: { type: Number, default: 1000 }
    }

    connect() {
        this.currentIndex = 0
        this.boundHandler = this.adjustBoundingBox.bind(this)
        this.loadHandler = () => this.adjustBoundingBox()

        // Initial setup on connection
        if (this.imageTarget.complete) {
            this.adjustBoundingBox()
        } else {
            this.imageTarget.addEventListener('load', this.loadHandler)
        }

        // Error handling for images
        this.imageTarget.addEventListener('error', (e) => {
            console.error('Image failed to load:', e)
            // Optionally skip to next image
            this.next()
        })

        // Watch for container resizing
        this.resizeObserver = new ResizeObserver(this.boundHandler)
        this.resizeObserver.observe(this.imageTarget)

        if (this.hasUrlsValue && this.urlsValue.length > 0) {
            this.startCycling()
        }
    }

    disconnect() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect()
        }
        // Clean up event listeners
        this.imageTarget.removeEventListener('load', this.loadHandler)
        this.imageTarget.removeEventListener('error', this.errorHandler)
    }

    updateImageAndBox() {
        const currentData = this.urlsValue[this.currentIndex]

        if (!currentData) {
            console.warn('No data for current index:', this.currentIndex)
            return
        }

        // Update image and handle any existing load listeners
        if (currentData.url) {
            this.imageTarget.src = currentData.url
        }
    }

    adjustBoundingBox() {
        if (!this.hasBoundingBoxTarget) return

        const currentData = this.urlsValue[this.currentIndex]
        if (!currentData?.bounding_box) {
            console.warn('No bounding box data for index:', this.currentIndex)
            return
        }

        const box = currentData.bounding_box

        // Safety check for natural dimensions
        if (!this.imageTarget.naturalWidth || !this.imageTarget.naturalHeight) {
            console.warn('Image natural dimensions not available')
            return
        }

        try {
            // Calculate percentages based on the natural image dimensions
            const topPercentage = (box.top / this.imageTarget.naturalHeight) * 100
            const leftPercentage = (box.left / this.imageTarget.naturalWidth) * 100
            const widthPercentage = (box.width / this.imageTarget.naturalWidth) * 100
            const heightPercentage = (box.height / this.imageTarget.naturalHeight) * 100

            // Apply percentage-based positioning
            this.boundingBoxTarget.style.top = `${topPercentage}%`
            this.boundingBoxTarget.style.left = `${leftPercentage}%`
            this.boundingBoxTarget.style.width = `${widthPercentage}%`
            this.boundingBoxTarget.style.height = `${heightPercentage}%`
        } catch (error) {
            console.error('Error adjusting bounding box:', error)
        }
    }

    startCycling() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
        }

        this.updateImageAndBox()
        this.intervalId = setInterval(() => {
            this.currentIndex = (this.currentIndex + 1) % this.urlsValue.length
            this.updateImageAndBox()
        }, this.intervalValue)
    }

    next() {
        this.currentIndex = (this.currentIndex + 1) % this.urlsValue.length
        this.updateImageAndBox()
    }
}