import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

// Initialize popovers on first load
document.addEventListener('DOMContentLoaded', initializePopovers)

// Initialize popovers on Turbo navigation
document.addEventListener('turbo:render', () => {
    // Use requestAnimationFrame to ensure table is initialized first
    requestAnimationFrame(() => {
        requestAnimationFrame(initializePopovers)
    })
})

function initializePopovers() {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        // Clean up existing popovers
        var existingPopover = bootstrap.Popover.getInstance(popoverTriggerEl)
        if (existingPopover) {
            existingPopover.dispose()
        }
        // Create new popover
        return new bootstrap.Popover(popoverTriggerEl)
    })
}