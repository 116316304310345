import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"


export default class extends Controller {
    static values = {
        savePath: String,
        debounceMs: { type: Number, default: 500 }
    }

    connect() {
        // Create debounced save function with the correct debounce time
        this.debouncedSave = debounce(() => {
            this.save()
        }, this.debouncesMsValue || 500)

        this.initializeSwitches()
        this.initializeForm()

        // Set initial form state based on general switch
        const generalSwitch = this.element.querySelector('#general_enabled')
        if (generalSwitch) {
            this.toggleFormState(generalSwitch.checked)
        }
    }

    disconnect() {
        if (this.debouncedSave) {
            this.debouncedSave.cancel()
        }
    }

    initializeSwitches() {
        // Get the token from the form's ID
        const formId = this.element.id;  // e.g. "general_abc123"
        const token = formId.split('_')[1];  // e.g. "abc123"

        const switches = [
            {
                id: 'general_enabled',
                handler: (checked) => this.toggleFormState(checked)
            },
            {
                baseId: 'automatic_translation',
                handler: (checked) => this.toggleCardVisibility(`automatic_translation_${token}_card`, checked)
            },
            {
                baseId: 'data_retention',
                handler: (checked) => this.toggleCardVisibility(`data_retention_${token}_card`, checked)
            },
            {
                baseId: 'hardblock_usa',
                handler: (checked) => this.toggleCardVisibility(`hardblock_usa_${token}_card`, checked)
            },
            {
                baseId: 'enforce_mobile_phone',
                handler: (checked) => this.toggleCardVisibility(`enforce_mobile_phone_${token}_card`, checked)
            },
            {
                baseId: 'decision_engine',
                handler: (checked) => this.toggleCardVisibility(`decision_engine_${token}_card`, checked)
            },
            {
                baseId: 'decision_engine',
                handler: (checked) => this.toggleCardVisibility(`decision_engine_${token}_card_off`, !checked)
            },
            {
                baseId: 'session_expiry_custom_time',
                handler: (checked) => this.toggleCardVisibility(`session_expiry_custom_time_${token}_card`, checked)
            }
        ]

        switches.forEach((switchConfig) => {
            // For the general switch, use the original ID
            const elementId = switchConfig.id || `${switchConfig.baseId}_${token}`;
            const switchElement = this.element.querySelector(`#${elementId}`);
            if (!switchElement) return;

            // Set initial visibility state
            if (elementId !== 'general_enabled') {
                const cardId = `${switchConfig.baseId}_${token}_card`;
                this.toggleCardVisibility(cardId, switchElement.checked);
            }

            // Add change listener
            switchElement.addEventListener("change", (event) => {
                switchConfig.handler(event.target.checked);
                this.save();
            })
        })
    }

    initializeForm() {
        // Use this.element to get the form element that this controller is attached to
        const form = this.element
        if (!form) return

        // Listen for changes on all form elements
        form.querySelectorAll('input, select, textarea').forEach(element => {
            if (element.type === 'submit' || element.id === 'general_enabled') return

            if (element.type === "text" || element.tagName === "TEXTAREA") {
                // Create a unique debounced save function for each text input
                const debouncedSaveForInput = debounce(() => {
                    this.save()
                }, this.debouncesMsValue || 500)

                element.addEventListener("input", () => debouncedSaveForInput())
                element.addEventListener("blur", () => {
                    debouncedSaveForInput.cancel()
                    this.save()
                })
            } else {
                // For all other inputs (select, checkbox, radio), save immediately
                element.addEventListener("change", () => this.save())
            }
        });

        // Specific logic for return_url_input focus/blur
        const returnUrlInput = this.element.querySelector('#level_return_url');
        const helpCard = this.element.querySelector('#return_url_help_card');

        if (returnUrlInput && helpCard) {
            returnUrlInput.addEventListener('focus', () => {
                helpCard.style.display = 'block';
            });

            returnUrlInput.addEventListener('blur', () => {
                //helpCard.style.display = 'none';
            });
        }

        // Handle form submission
        form.addEventListener('submit', (event) => {
            event.preventDefault()
            this.save()
        })
    }

    toggleFormState(enabled) {
        // Toggle visibility of status alerts
        this.toggleVisibility('general-enabled', enabled)
        this.toggleVisibility('general-disabled', !enabled)

        // Toggle form section
        const formSection = this.element.querySelector('#kyc-general-form')
        if (!formSection) return

        formSection.classList.toggle('opacity-50', !enabled)

        // Disable/enable all form elements except the general switch
        formSection.querySelectorAll('input, select, textarea, button').forEach(element => {
            if (element.id !== 'general_enabled') {
                element.disabled = !enabled
            }
        })

        // Toggle pointer events on clickable elements
        formSection.querySelectorAll('a, button').forEach(element => {
            element.style.pointerEvents = enabled ? 'auto' : 'none'
        })
    }

    toggleVisibility(elementId, show) {
        const element = this.element.querySelector(`#${elementId}`)
        if (!element) return

        element.classList.toggle('d-none', !show)
        element.classList.toggle('show', show)
    }

    toggleCardVisibility(cardId, show) {
        const card = this.element.querySelector(`#${cardId}`)
        if (!card) return

        if (show) {
            card.classList.remove('d-none')
            card.classList.add('show')
        } else {
            card.classList.add('d-none')
            card.classList.remove('show')
        }
    }

    async save() {
        const form = this.element;
        if (!form || !form.elements) {
            console.error("Form not found or invalid.");
            return;
        }

        // Get all form elements and initialize FormData
        const formElements = Array.from(form.elements); // Convert to an array for robust iteration
        const formData = new FormData();

        // Iterate over form elements
        for (let element of formElements) {
            // Skip elements without a name or those that are disabled
            if (!element.name || element.disabled) continue;

            if (element.type === 'checkbox') {
                // Handle checkboxes: include whether checked or not
                formData.set(`general[${element.name}]`, element.checked ? '1' : '0');
            } else if (element.type !== 'submit') {
                // Handle all other input types, excluding submit buttons
                formData.set(`general[${element.name}]`, element.value);
            }
        }





        const submitButtons = form.querySelectorAll('button[type="submit"]')
        submitButtons.forEach(button => button.disabled = true)

        try {
            const response = await fetch(form.action, {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'text/vnd.turbo-stream.html',
                    'X-CSRF-Token': this.getCSRFToken()
                }
            })

            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            const html = await response.text()
            Turbo.renderStreamMessage(html)

        } catch (error) {
            console.error('Save failed:', error)
        } finally {
            submitButtons.forEach(button => button.disabled = false)
        }
    }

    getCSRFToken() {
        const element = document.querySelector('meta[name="csrf-token"]')
        return element ? element.getAttribute('content') : null
    }
}