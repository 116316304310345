import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static values = {
    tableConfig: {
      type: Object,
      default: {
        '#datatable': {
          autoWidth: false,
          columns: [
            { data: 'name', width: '30%' },
            { data: 'feature', orderable: false, width: '306px'  },
            { data: 'created' },
            { data: 'actions', orderable: false }
          ],
          order: [[0, 'asc']],
          "createdRow": function (row, data, dataIndex) {
            $(row).css('cursor', 'pointer');


            // Add click handler to all cells except the last two columns
            $(row).find('td:not(:last-child):not(:nth-last-child(2))').on('click', function() {
              const url = $(row).data('url');
              if (url) {
                // Use regular visit to ensure proper controller cleanup
                Turbo.visit(url, { action: 'advance' });
              }
            });

            // Add hover effect to the entire row
            $(row).hover(
                function () {
                  $(this).css({
                    'background-color': '#f5f5f5',
                    'transition': 'background-color 0.2s ease'
                  });
                },
                function () {
                  $(this).css('background-color', '');
                }
            );
          },
          pageLength: 10,
          processing: true,
          language: {
            emptyTable: "No KYC level entries"
          }
        }
      }
    }
  }

  initialize() {
    // Store bound methods to ensure proper event listener cleanup
    this._handleBeforeCache = this.handleBeforeCache.bind(this);
    this._handleRender = this.handleRender.bind(this);

    // Add Turbo cache handling
    document.addEventListener('turbo:before-cache', this._handleBeforeCache);
    document.addEventListener('turbo:render', this._handleRender);
  }

  connect() {
    // Wait for any pending transitions to complete
    requestAnimationFrame(() => {
      this.initializeTables();
    });
  }

  disconnect() {
    // Remove event listeners using stored bound methods
    document.removeEventListener('turbo:before-cache', this._handleBeforeCache);
    document.removeEventListener('turbo:render', this._handleRender);

    this.cleanupTables();
  }

  handleBeforeCache() {
    if (this.element) {
      this.cleanupTables();
    }
  }

  handleRender() {
    // Only reinitialize if this controller is still connected
    if (this.element && this.element.isConnected) {
      requestAnimationFrame(() => {
        this.initializeTables();
      });
    }
  }

  cleanupTables() {
    try {
      const tables = this.element?.querySelectorAll('table');
      if (!tables) return;

      tables.forEach(table => {
        if (table && $.fn.DataTable.isDataTable(table)) {
          const dt = $(table).DataTable();
          if (dt) {
            dt.destroy();
            // Preserve original HTML
            if (!table.dataset.originalHtml) {
              table.dataset.originalHtml = table.innerHTML;
            }
          }
        }
      });
    } catch (error) {
      console.warn('Error during table cleanup:', error);
    }
  }

  initializeTables() {
    try {
      const configs = this.tableConfigValue;
      if (!configs) return;

      Object.entries(configs).forEach(([tableId, config]) => {
        const table = this.element?.querySelector(`table${tableId}`);
        if (!table) return;

        // Restore original HTML if it exists
        if (table.dataset.originalHtml) {
          table.innerHTML = table.dataset.originalHtml;
        }

        // Clean up existing instance if any
        if ($.fn.DataTable.isDataTable(table)) {
          $(table).DataTable().destroy();
        }

        // Initialize new instance with preserved config
        const finalConfig = {
          ...config,
          destroy: true,
          responsive: true,
          dom: 'lBfrtip',
        };

        new DataTable(table, finalConfig);
      });
    } catch (error) {
      console.error('Error initializing tables:', error);
    }
  }
}